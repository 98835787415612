













import { defineComponent } from '@vue/composition-api'
import router from '@/router'

export default defineComponent({
  name: 'DeskMission',
  setup() {
    const bg =
      'https://zyb-zhike-file.cdnjtzy.com/mis/59ad930d-65ff-42c6-be3b-c77299ea0279.jpg'
    const handleRedirect = () => {
      router.push({
        path: '/introduct'
      })
    }
    return {
      bg,
      handleRedirect
    }
  }
})
